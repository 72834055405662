import React from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'

function TagRoute({
  pageContext: { tag },
  data: {
    site: {
      siteMetadata: { title },
    },
    allMarkdownRemark: { edges: posts, totalCount },
  },
}) {
  const postLinks = posts.map(post => (
    <li key={post.node.frontmatter.slug}>
      <Link to={post.node.frontmatter.slug}>
        <h2>{post.node.frontmatter.title}</h2>
      </Link>
    </li>
  ))
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? '' : 's'
  } tagged with “${tag}”`

  return (
    <Layout>
      <section className="section">
        <Helmet title={`${tag} | ${title}`} />
        <div className="container">
          <h3 className="title">{tagHeader}</h3>
          <ul className="taglist">{postLinks}</ul>
          <p>
            <Link to="/tags/">Browse all tags</Link>
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            slug
            title
          }
        }
      }
    }
  }
`
